@keyframes animate {
    0% {
      background-position: -250%;
    }
    100% {
      background-position: 250%;
    }
  }
  
  @keyframes animate2 {
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  }
  
.loadingCard{
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
  /* background: linear-gradient(90deg, rgb(190, 190, 190), #fff,rgb(190, 190, 190));
  background-repeat:repeat; */
  background-size: 80%;
  /* animation: animate2 2s linear infinite; */
}


.btn {
  opacity: 0.7;
  transition: 0.3s;
  transition: transform .2s;
}

.btnDisabled {
  opacity: 0.4;
}

.btn:hover { opacity: 1; transform: scale(1.2);}
.btn:active {box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}

.dropzone {
  background: white;
  border-radius: 5px;
  border: 2px dashed #00acc1;
  border-image: none;
  min-height: 310px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.dropzoneError {
  background: white;
  border-radius: 5px;
  border: 2px dashed red;
  border-image: none;
  min-height: 310px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.dropzone2 {
  background: white;
  border-radius: 5px;
  border: 2px dashed #00acc1;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.dropzone2Error {
  background: white;
  border-radius: 5px;
  border: 2px dashed red;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
/* 
.previewImage {
  transition: 0.3s;
  transition: transform .2s;
}
.previewImage:hover {  transform: scale(1.5);  z-index: 10000;} */

.widget {
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
}
.widget[type="ticket"] {
  width: auto;
}
.widget[type="ticket"] .top > div,
.widget[type="ticket"] .bottom > div {
  padding: 0 18px;
}
.widget[type="ticket"] .top > div:first-child,
.widget[type="ticket"] .bottom > div:first-child {
  padding-top: 18px;
}
.widget[type="ticket"] .top > div:last-child,
.widget[type="ticket"] .bottom > div:last-child {
  padding-bottom: 18px;
}
.widget[type="ticket"] .top img,
.widget[type="ticket"] .bottom img {
  padding: 18px 0;
}
.widget[type="ticket"] .top,
.widget[type="ticket"] .bottom,
.widget[type="ticket"] .rip {
  background-color: #fff;
}
.widget[type="ticket"] .top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.widget[type="ticket"] .top .deetz {
  padding-bottom: 10px !important;
}
.widget[type="ticket"] .bottom {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 18px;
  height: 30px;
  padding-top: 10px;
}
.widget[type="ticket"] .bottom .barcode {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF4AAAABCAYAAABXChlMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAACPSURBVChTXVAJDsMgDOsrVpELiqb+/4c0DgStQ7JMYogNh2gdvg5VfXFCRIZaC6BOtnoNFpvaumNmwb/71Frrm8XvgYkker1/g9WzMOsohaOGNziRs5inDsAn8yEPengTapJ5bmdZ2Yv7VvfPN6AH2NJx7nOWPTf1/78hoqgxhzw3ZqYG1Dr/9ur3y8vMxgNZhcAUnR4xKgAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  min-width: 58px;
}
.widget[type="ticket"] .bottom .buy {
  display: block;
  font-size: 12px;
  font-weight: bold;
  background-color: #5D9CEC;
  padding: 0 18px;
  line-height: 30px;
  border-radius: 15px;
  color: #fff;
  text-decoration: none;
}
.widget[type="ticket"] .rip {
  height: 20px;
  margin: 0 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAACCAYAAAB7Xa1eAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAAAaSURBVBhXY5g7f97/2XPn/AcCBmSMQ+I/AwB2eyNBlrqzUQAAAABJRU5ErkJggg==);
  background-size: 4px 2px;
  background-repeat: repeat-x;
  background-position: center;
  position: relative;
  box-shadow: 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
}
.widget[type="ticket"] .rip:before, .widget[type="ticket"] .rip:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  border: 5px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 100%;
  pointer-events: none;
}
.widget[type="ticket"] .rip:before {
  left: -10px;
}
.widget[type="ticket"] .rip:after {
  -webkit-transform: translate(-50%, -50%) rotate(225deg);
          transform: translate(-50%, -50%) rotate(225deg);
  right: -40px;
}
.widget .-bold {
  font-weight: bold;
}
.overlayLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  /* transition: .5s ease;
  background-color: #00acc1; */
}

.container {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .1s ease;
  background-color: #00acc1;
}
.overlay2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%;
  width: 100%;
  opacity: 0;
  transition: .1s ease;
  background-color: #008CBA;
}
.container:hover .overlay {
  opacity: 1;
}
.container:hover .overlay2 {
  opacity: 1;
}
.text {
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.textBlack {
  color: black;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.niftronColor{
  background:
    "linear-gradient(60deg, "#000080", "#fc0003")",
}